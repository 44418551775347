import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: '20px', backgroundColor: '#f8f8f8', marginTop: '20px' }}>
      <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 500 }}>
        Contact us: <a href="tel:+254724440202" style={{ color: '#008080', textDecoration: 'none' }}>0724440202</a>
      </Typography>
    </Box>
  );
};

export default Footer;
